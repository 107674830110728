import React from "react"
import { graphql } from "gatsby"
import { Container, Heading, Box } from "@theme-ui/components"
import Layout from "../layouts/Layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import LatestNews from "../components/LatestNews"
import { useFavicon } from "../hooks/useFavicon"
import EventsAndNewsCarousel from "../components/EventsAndNewsCarousel"
import BigTitle from "../components/BigTitle"
import EventsCollection from "../components/EventsCollection"
import NewsCollection from "../components/NewsCollection"
import MonthsCollection from "../components/MonthsCollection"

const Home = ({ data: { page, site, news, events } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      {page.content.map(
        block =>
          block.model &&
          block.model.apiKey && (
            <Box as="section" key={block.id}>
              {block.model.apiKey === "events_and_news_carousel" && (
                <EventsAndNewsCarousel block={block} />
              )}
              {block.model.apiKey === "big_title" && <BigTitle block={block} />}
              {block.model.apiKey === "events_collection" && events.nodes.length > 0 && (
                <EventsCollection block={block} events={events.nodes} />
              )}
              {block.model.apiKey === "news_collection" && news && news.nodes.length > 0 && (
                <NewsCollection block={block} news={news.nodes} />
              )}
              {block.model.apiKey === "months_collection" && (
                <MonthsCollection block={block} />
              )}
            </Box>
          )
      )}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      body {
        value
      }
      content {
        ... on DatoCmsEventsAndNewsCarousel {
          title
          placeholderImage {
            url
            mimeType
            video {
              streamingUrl
              thumbnailUrl
            }
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
          pages {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsBlogPage {
                  ...BlogDetails
                }
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
                ... on DatoCmsNews {
                  ...NewsDetails
                  ...NewsAllSlugLocales
                }
              }
            }
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
          }
          eventsAndNewsCollection {
            ... on DatoCmsNews {
              id
            }
            ... on DatoCmsEvent {
              id
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsBigTitle {
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsEventsCollection {
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsNewsCollection {
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsMonthsCollection {
          title
          months {
            ...PageDetails
            ...PageTreeParent
            ...AllSlugLocales
            thumbnailImage {
              url
              gatsbyImageData(
                placeholder: NONE
                forceBlurhash: false
                imgixParams: {
                  fit: "crop"
                  ar: "1:1"
                  h: "1000"
                }
              )
            }
            body {
              value
            }
          }
          model {
            apiKey
          }
        }
      }
    }
    events: allDatoCmsEvent(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 3
    ) {
      nodes {
        ...EventDetails
        ...EventAllSlugLocales
        ...EventMeta
      }
    }
    news: allDatoCmsNews(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 3
    ) {
      nodes {
        ...NewsDetails
        ...NewsAllSlugLocales
        ...NewsMeta
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`

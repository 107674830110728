import React, { useEffect, useState } from "react"
import { Box, Container, Heading, Grid, Flex, Image, Text } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import RichContentStructuredText from "../RichContentStructuredText"
import { InboundLink } from "../../utils/link"
import { getPagePath } from "../../utils/path"
import ArrowLongRight from "../../assets/images/arrow-long-right-black.inline.svg"
import { isMonth } from "../../utils/currentMonth"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"

const colors = [
  "#376BB0",
  "#5169AE",
  "#6567AB",
  "#7664A8",
  "#8660A4",
  "#945BA0",
  "#A0569C",
  "#AC4F98",
  "#B74793",
  "#C13E8F",
  "#CA358B",
  "#D42887",
  "#DD1482",
  "#E6007E",
]

const MonthsCollection = ({ block }) => {
  const mediaIndex = useBreakpointIndex()

  const { title, months } = block
  const [currentPastMonths, setCurrentPastMonths] = useState()
  const [activeIndex, setActiveIndex] = useState(new Date().getMonth())

  useEffect(() => {
    months &&
      setCurrentPastMonths(
        months.filter(month => isMonth(month.title.split(" ")[0]))
      )
  }, [])

  return (
    <Box>
      <Container sx={{ py: [0, 0, 0] }}>
        <Heading
          as="div"
          sx={{
            mb: [3, 4],
            h2: {
              fontSize: [7, 8, 9, 11], //"4.5rem",
              fontFamily: "heading",
              lineHeight: "heading",
              fontWeight: "heading",
              color: "text",
              mb: [5, 7],
              mt: 4,
              fontWeight: "400",
              em: {
                "-webkit-text-stroke": "1px #181818",
                color: "transparent",
                fontStyle: "normal",
                fontFamily: "body",
              },
            },
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Container>
      <Container sx={{ py: [0, 0], px: [0, 4] }}>
        <Box
          sx={{
            borderBottom: "1px solid",
            borderColor: "dark",
            mb: [0, 0],
          }}
        ></Box>
      </Container>

      <Container sx={{ position: "relative", px: [0, 0, 4, 4] }}>
        <Box sx={{ position: "relative", width: "100%" }}>
          {mediaIndex > 1 ? (
            <>
              <Grid
                columns={["1fr", "1fr", ".47fr .53fr", ".47fr .53fr"]}
                gap={[0]}
                sx={{
                  opacity: "0",
                  backgroundColor: colors[0],
                }}
              >
                <Box
                  sx={{
                    img: {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      minHeight: ["369px", "600px"],
                    },
                  }}
                >
                  <Image
                    src={months[0].heroImage.video.thumbnailUrl}
                    alt={`Immmagine foto ${months[0].title}`}
                  />
                </Box>
                <Flex
                  sx={{
                    justifyContent: "space-between",
                    flexDirection: ["column", "column", "row", "row"],
                  }}
                >
                  <Box sx={{ p: [3] }}>
                    <Text
                      as="h2"
                      variant="h4"
                      sx={{
                        display: ["block", "block", "none", "none"],
                        my: [0, 0],
                        color: "light",
                        fontSize: [4, 5],
                        fontWeight: "bold",
                      }}
                    >
                      {months[0].title}
                    </Text>
                    <Box
                      sx={{
                        mb: [4, 6],
                        maxWidth: "380px",
                        p: {
                          color: "light",
                          fontSize: [4, 8],
                          lineHeight: "1",
                        },
                      }}
                    >
                      <RichContentStructuredText text={months[0].body} />
                    </Box>
                    <InboundLink
                      href={getPagePath(months[0], months[0].locale)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        svg: {
                          fill: "light",
                          "#Fill-3": {
                            fill: "light",
                          },
                          stroke: "light",
                        },
                      }}
                      variant="links.simple"
                    >
                      Scopri di più
                      <Box sx={{ ml: [2] }}>
                        <ArrowLongRight />
                      </Box>
                    </InboundLink>
                  </Box>
                  <Box
                    sx={{
                      display: ["none", "none", "flex", "flex"],
                      maxWidth: "150px",
                      p: [2],
                      pb: [4],
                      pt: [3],
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box>
                      <Text
                        as="h2"
                        variant="h4"
                        sx={{
                          color: "light",
                          my: [0, 0],
                          fontWeight: "bold",
                        }}
                      >
                        01
                      </Text>
                    </Box>
                    <Text
                      as="h2"
                      variant="h4"
                      sx={{
                        my: [0, 0],
                        color: "light",
                        transform: "rotate(180deg)",
                        fontSize: [4, 5],
                        writingMode: "vertical-rl",
                        textOrientation: "mixed",
                      }}
                    >
                      {months[0].title}
                    </Text>
                  </Box>
                  <Box
                    sx={{
                      display: ["block", "block", "none", "none"],
                      p: [3],
                      textAlign: "right",
                    }}
                  >
                    <Text
                      as="h2"
                      variant="h4"
                      sx={{
                        color: "light",
                        my: [0, 0],
                        fontWeight: "bold",
                      }}
                    >
                      01
                    </Text>
                  </Box>
                </Flex>
              </Grid>

              <Box
                sx={{
                  display: "block",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  overflow: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {currentPastMonths &&
                  currentPastMonths.map((month, index) => (
                    <Box
                      sx={{
                        backgroundColor: colors[index]
                          ? colors[index]
                          : colors[0],
                        position: "relative",
                        display: "block",
                        float: "right",
                        width: "58px",
                        minWidth: "58px",
                        maxWidth: "58px",
                        overflow: "hidden",
                        "&.active": {
                          maxWidth: "100%",
                          width: "100%",
                          height: "100%",
                          backgroundColor: colors[index]
                            ? colors[index]
                            : colors[0],
                          ".static": {
                            width: "100%",
                            position: "static",
                          },
                        },
                        WebkitTransition: "all 1s ease",
                        MozTransition: "all 1s ease",
                        msTransition: "all 1s ease",
                        OTransition: "all 1s ease",
                        transition: "all 1s ease",
                      }}
                      as="section"
                      id={"acc" + index}
                      className={activeIndex === index && "active"}
                    >
                      <Box
                        className="static"
                        sx={{
                          maxWidth: activeIndex !== index ? "58px" : "100%",
                          transition: "all .3s linear",
                          display: "flex",
                          minWidth: "58px",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            height: "100%",
                            display: ["none", "none", "flex", "flex"],
                            maxWidth: "58px",
                            minWidth: "58px",
                            p: [2],
                            pb: [4],
                            pt: [3],
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                          }}
                          onClick={() => setActiveIndex(index)}
                        >
                          <Box>
                            <Text
                              as="h2"
                              variant="h4"
                              sx={{
                                color: "light",
                                my: [0, 0],
                                fontWeight: "bold",
                              }}
                            >
                              {index + 1 > 9 ? index + 1 : "0" + (index + 1)}
                            </Text>
                          </Box>
                          <Text
                            as="h2"
                            variant="h4"
                            sx={{
                              my: [0, 0],
                              color: "light",
                              transform: "rotate(180deg)",
                              fontSize: [4, 5],
                              writingMode: "vertical-rl",
                              textOrientation: "mixed",
                            }}
                          >
                            {month.title}
                          </Text>
                        </Box>
                        <Grid
                          className="contant-shown"
                          columns={["1fr", "1fr", "minmax(0px,664px) auto"]}
                          sx={{
                            width: "100%",
                            opacity: activeIndex === index ? 1 : 0,
                            transition: "all .1s linear",
                          }}
                          gsp={[0]}
                        >
                          <Box
                            sx={{
                              ".gatsby-image-wrapper": {
                                height: "100%",
                                img: {
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                },
                              },
                            }}
                          >
                            {month.thumbnailImage.gatsbyImageData ? (
                              <GatsbyImage
                                image={month.thumbnailImage.gatsbyImageData}
                              />
                            ) : (
                              <Image
                                src={month.thumbnailImage.video.thumbnailUrl}
                                alt={`Immmagine foto ${month.title}`}
                              />
                            )}
                          </Box>
                          <Box
                            sx={{
                              p: [3],
                              opacity: activeIndex === index ? 1 : 0,
                              transition: "all .1s linear .6s",
                            }}
                          >
                            <Text
                              as="h2"
                              variant="h4"
                              sx={{
                                display: ["block", "block", "none", "none"],
                                my: [0, 0],
                                color: "light",
                                fontSize: [4, 5],
                                fontWeight: "bold",
                              }}
                            >
                              {month.title}
                            </Text>
                            <Box
                              sx={{
                                mb: [4, 6],
                                maxWidth: "380px",
                                p: {
                                  color: "light",
                                  fontSize: [4, 8],
                                  lineHeight: "1",
                                },
                              }}
                            >
                              <RichContentStructuredText text={month.body} />
                            </Box>
                            <InboundLink
                              href={getPagePath(month, month.locale)}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                svg: {
                                  fill: "light",
                                  "#Fill-3": {
                                    fill: "light",
                                  },
                                  stroke: "light",
                                },
                              }}
                              variant="links.simple"
                            >
                              Scopri di più
                              <Box sx={{ ml: [2] }}>
                                <ArrowLongRight />
                              </Box>
                            </InboundLink>
                          </Box>
                        </Grid>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors[activeIndex],
                  opacity: 1,
                  transition: "all .3s linear",
                  pb: [4],
                }}
              >
                <Box
                  sx={{
                    img: {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      minHeight: ["369px", "600px"],
                    },
                  }}
                >
                  {months[activeIndex].heroImage.gatsbyImageData ? (
                    <GatsbyImage
                      image={months[activeIndex].heroImage.gatsbyImageData}
                    />
                  ) : (
                    <Image
                      src={months[activeIndex].heroImage.video.thumbnailUrl}
                      alt={`Immmagine foto ${months[activeIndex].title}`}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    p: [3],
                    opacity: 1,
                    transition: "all .1s linear .6s",
                  }}
                >
                  <Text
                    as="h2"
                    variant="h4"
                    sx={{
                      display: ["block", "block", "none", "none"],
                      my: [0, 0],
                      color: "light",
                      fontSize: [4, 5],
                      fontWeight: "bold",
                    }}
                  >
                    {months[activeIndex].title}
                  </Text>
                  <Box
                    sx={{
                      mb: [4, 6],
                      maxWidth: "380px",
                      p: {
                        color: "light",
                        fontSize: [4, 8],
                        lineHeight: "1",
                      },
                    }}
                  >
                    <RichContentStructuredText
                      text={months[activeIndex].body}
                    />
                  </Box>
                  <InboundLink
                    href={getPagePath(
                      months[activeIndex],
                      months[activeIndex].locale
                    )}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      svg: {
                        fill: "light",
                        "#Fill-3": {
                          fill: "light",
                        },
                        stroke: "light",
                      },
                    }}
                    variant="links.simple"
                  >
                    Scopri di più
                    <Box sx={{ ml: [2] }}>
                      <ArrowLongRight />
                    </Box>
                  </InboundLink>
                </Box>
              </Box>
              <Grid
                columns={[6, 6, `repeat(${months.length},58px)`]}
                gap={[0]}
                sx={{
                  // height: ["58px", "58px", null ],
                  width: "100%",
                }}
              >
                {currentPastMonths &&
                  currentPastMonths.map((month, index) => (
                    <Box
                      sx={{
                        backgroundColor: colors[index],
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        height: "58px",
                      }}
                      onClick={() => setActiveIndex(index)}
                    >
                      <Text
                        as="h2"
                        variant="h4"
                        sx={{
                          textAlign: "center",
                          color: "light",
                          my: [0, 0],
                          fontWeight: "bold",
                        }}
                      >
                        {index + 1 > 9 ? index + 1 : "0" + (index + 1)}
                      </Text>
                    </Box>
                  ))}
              </Grid>
            </Box>
          )}

          {/* {months.map((month, index) => (
            <Box
              sx={{
                backgroundColor: colors[index],
                width: `calc(100% + ${(index + 1 - months.length) * 58}px )`,
                top: 0,
                zIndex: 9999 - index,
                height: "100%",
                position: "absolute",
                right: `calc(0px - ${(index + 1 - months.length) * 58}px )`,
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Flex
                sx={{
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  columns={["1fr", "1fr", "minmax(0px,664px) auto"]}
                  sx={{
                    width: "100%",
                  }}
                  gsp={[0]}
                >
                  <Box
                    sx={{
                      img: {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        minHeight: ["369px", "600px"],
                      },
                    }}
                  >
                    {month.heroImage.gatsbyImageData ? (
                      <GatsbyImage image={month.heroImage.gatsbyImageData} />
                    ) : (
                      <Image
                        src={month.heroImage.video.thumbnailUrl}
                        alt={`Immmagine foto ${month.title}`}
                      />
                    )}
                  </Box>
                  <Box sx={{ p: [3] }}>
                    <Text
                      as="h2"
                      variant="h4"
                      sx={{
                        display: ["block", "block", "none", "none"],
                        my: [0, 0],
                        color: "light",
                        fontSize: [4, 5],
                        fontWeight: "bold",
                      }}
                    >
                      {month.title}
                    </Text>
                    <Box
                      sx={{
                        mb: [4, 6],
                        maxWidth: "380px",
                        p: {
                          color: "light",
                          fontSize: [4, 8],
                          lineHeight: "1",
                        },
                      }}
                    >
                      <RichContentStructuredText text={month.body} />
                    </Box>
                    <InboundLink
                      href={getPagePath(month, month.locale)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        svg: {
                          fill: "light",
                          "#Fill-3": {
                            fill: "light",
                          },
                          stroke: "light",
                        },
                      }}
                      variant="links.simple"
                    >
                      Scopri di più
                      <Box sx={{ ml: [2] }}>
                        <ArrowLongRight />
                      </Box>
                    </InboundLink>
                  </Box>
                </Grid>
                <Box
                  sx={{
                    maxWidth: "58px",
                    minWidth: "58px",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      display: ["none", "none", "flex", "flex"],
                      maxWidth: "150px",
                      minWidth: "58px",
                      p: [2],
                      pb: [4],
                      pt: [3],
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() => setActiveIndex(index)}
                  >
                    <Box>
                      <Text
                        as="h2"
                        variant="h4"
                        sx={{
                          color: "light",
                          my: [0, 0],
                          fontWeight: "bold",
                        }}
                      >
                        {index + 1 > 9 ? index + 1 : "0" + (index + 1)}
                      </Text>
                    </Box>
                    <Text
                      as="h2"
                      variant="h4"
                      sx={{
                        my: [0, 0],
                        color: "light",
                        transform: "rotate(180deg)",
                        fontSize: [4, 5],
                        writingMode: "vertical-rl",
                        textOrientation: "mixed",
                      }}
                    >
                      {month.title}
                    </Text>
                  </Box>
                </Box>
              </Flex>
            </Box>
          ))} */}
        </Box>
      </Container>
    </Box>
  )
}

export default MonthsCollection

import React from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { getNewsPath } from "../../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink } from "../../utils/link"

const EventThumb = ({ event, small }) => {
  console.log(event)
  return (
    <Box
      sx={{
        backgroundColor: "lightBackground",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <InboundLink
        to={getNewsPath(event, event.locale)}
        sx={{ textDecoration: "none" }}
      >
        <Grid columns={[1, small ? 1 : "5fr 3fr"]} gap={0}>
          <Box
            sx={{
              ".gatsby-image-wrapper": {
                height: "100%",
                minHeight: ["auto", "441px"],
              },
            }}
          >
            <GatsbyImage image={event.heroImage.gatsbyImageData} alt="" />
          </Box>
          <Flex
            sx={{
              p: [3,4],
              py:[4,4],
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Text
                sx={{
                  fontSize: [2, 2],
                  color: "dark",
                }}
              >
                {new Date(event.eventDate).toLocaleDateString(undefined, {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
                {" "}
                {new Date(event.eventDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
              </Text>
            </Box>
            <Box
              sx={{
                mt: [3],
                width: "100%",
              }}
            >
              <Heading
                sx={{
                  color: "dark",
                  fontSize: [3, 5],
                }}
              >
                {event.title}
              </Heading>
            </Box>
            <Box
              sx={{
                mt: [3],
                width: "100%",
              }}
            >
              <Text
                sx={{
                  color: "dark",
                  fontSize: [2, 2],
                  textDecoration: "none",
                }}
              >
                {event.eventLocation.anchor}
              </Text>
            </Box>
          </Flex>
        </Grid>
      </InboundLink>
    </Box>
  )
}

export default EventThumb

import React from "react"
import { Box, Container } from "theme-ui"
import LatestEvents from "../LatestEvents"

const EventsCollection = ({ block, events }) => {
  const { title } = block

  return (
    events.length >0 &&
    <Box>
        <LatestEvents events={events} title={title} />
    </Box>
  )
}
export default EventsCollection

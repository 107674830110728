import React from "react"
import { Box, Heading, Container } from "theme-ui"

const BigTitle = ({ block }) => {
  const { title } = block
  return (
    <Box>
      <Container>
        <Heading as="h1" variant="h1" sx={{ textAlign: "center", fontSize:[8,9,10,12], lineHeight:".9333" }}>
          {title}
        </Heading>
      </Container>
    </Box>
  )
}
export default BigTitle

import React, { useEffect, useState, useRef } from "react"
import {
  Box,
  Container,
  Text,
  Heading,
  Grid,
  Button,
} from "@theme-ui/components"
import EventThumb from "../EventThumb"

const LatestEvents = ({ title, events }) => {
  const [postsToShow, setPostsToShow] = useState([])
  const postsPerPage = 3
  let arrayForHoldingPosts = []
  const ref = useRef(postsPerPage)
  const loopWithSlice = (start, end) => {
    const slicedPosts = events.slice(start, end)
    setPostsToShow(postsToShow.concat(slicedPosts))
  }
  useEffect(() => {
    loopWithSlice(0, postsPerPage)
  }, [])

  const handleShowMorePosts = () => {
    loopWithSlice(ref.current, ref.current + postsPerPage)
    ref.current += postsPerPage
  }

  return (
    <Box sx={{ ".swiper-wrapper": { alignItems: "stretch" } }}>
      <Container >
        {title && (
          <Heading
            as="div"
            sx={{
              color: "text",
              textTransform: "lowercase",
              h2: {
                textTransform: "lowercase",
                lineHeight: ".818",
                fontSize: [7, 8, 9, 11], //"4.5rem",
                my: [0, 0],
                mt: 4,
                fontWeight: "heading",
                color: "text",
                fontFamily: "heading",
                em: {
                  "-webkit-text-stroke": "1px #181818",
                  color: "transparent",
                  fontStyle: "normal",
                  fontFamily: "body",
                },
              },
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
      </Container>
      <Container sx={{ py: [0, 4], px: [0, 4], pb:[0,0] }}>
        <Box
          sx={{ borderBottom: "1px solid", borderColor: "text", mb: [3, 4] }}
        ></Box>
      </Container>
      <Container>
        <Grid columns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]} gap={[4]}>
          {postsToShow.map(event => (
            <EventThumb event={event} small />
          ))}
        </Grid>
        {postsToShow.length < events.length && (
          <Box sx={{ mt: [4, 9], textAlign: "center" }}>
            <Button variant="links.dark" onClick={handleShowMorePosts}>
              scopri di più
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default LatestEvents

import React, { useState } from "react"
import { Box, Container, Heading, Grid, Flex, Image } from "theme-ui"
import { MagicLink } from "../../utils/magicLink"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import ReactPlayer from "react-player"

// Import Swiper styles
import "swiper/css"
import { GatsbyImage } from "gatsby-plugin-image"

const EventsAndNewsCarousel = ({ block }) => {
  const [videoReady, setVideoReady] = useState(false)
  const { title, placeholderImage, pages, eventsAndNewsCollection } = block

  const handleOnStart = (e)=>{
    console.log("handleOnStart",e)
    setVideoReady(true)
  }

  return (
    <Box>
      <Container sx={{ pt: ["0px", 0], px: [0, 0] }}>
        <Grid
          columns={[
            "1fr",
            "1fr",
            "minmax(300px,300px) auto",
            "minmax(300px,300px) auto",
          ]}
          gap={[3]}
        >
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              gridRow: ["2", "2", "1", "1"],
              px: [3, 4],
            }}
          >
            <Box sx={{ borderBottom: "7px solid", borderColor: "dark" }}>
              <Heading as="h2" variant="h3">
                {title}
              </Heading>
            </Box>
            <Flex sx={{ flexDirection: "column" }}>
              {pages.map((page, index) => (
                <Box sx={{ mb: [3], mt: index === 0 ? [4, 4, 4] : 0 }}>
                  <MagicLink
                    variant="links.dark"
                    sx={{
                      px: [4],
                      width: "100%",
                      display: "inline-block",
                    }}
                    item={page}
                  >
                    {page.anchor}
                  </MagicLink>
                </Box>
              ))}
            </Flex>
          </Flex>
          <Box
            sx={{
              gridRow: ["1", "1", "1", "1"],
            }}
          >
            {eventsAndNewsCollection.length > 0 ? (
              <Swiper
                spaceBetween={50}
                slidesPerView={3}
                onSlideChange={() => console.log("slide change")}
                onSwiper={swiper => console.log(swiper)}
              >
                <SwiperSlide>Slide 1</SwiperSlide>
                <SwiperSlide>Slide 2</SwiperSlide>
                <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide>
              </Swiper>
            ) : (
              <Box
                sx={{
                  position:"relative",
                  ".gatsby-image-wrapper": {
                    width: "100%",
                  },
                  video: {
                    position:"absolute",
                    top:0,
                    left:0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                {console.log("poster", placeholderImage.video.thumbnailUrl)}
                {placeholderImage.gatsbyImageData ? (
                  <GatsbyImage
                    image={placeholderImage.gatsbyImageData}
                    alt=""
                  />
                ) : (
                  <>
                      <Image sx={{
                        position:"relative",
                        top:0,
                        left:0
                      }} width="1102" height="810" src={placeholderImage.video.thumbnailUrl} />
                      <ReactPlayer
                        onPlay={handleOnStart}
                        onStart={handleOnStart}
                        onReady={handleOnStart}
                        title={"home-section-video"}
                        className="react-player"
                        url={
                          placeholderImage.video.streamingUrl
                            ? placeholderImage.video.streamingUrl
                            : placeholderImage.url
                        }
                        controls={false}
                        playing={true}
                        muted={true}
                        autoPlay={true}
                        light={false}
                        width="100%"
                        height="100%"
                        playsinline="playsinline"
                      />
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}
export default EventsAndNewsCarousel
